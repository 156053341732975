<script>
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      sitekey: process.env.VUE_APP_ROOT_reCAPTCHA,
      respon: "",
      nocaptcha: true,
      showPassword: false,
      isLoading: false,
    };
  },
  components: {
    VueRecaptcha,
  },
  validations: {
    email: { required, email },
    password: { required },
    respon: { required },
  },
  methods: {
    onVerify: function(res) {
      this.respon = res;
      this.nocaptcha = true;
    },
    onExpired: function() {
      console.log("Expired");
    },
    tryToLogIn() {
      this.submitted = true;
      this.isLoading = true;
      this.$v.email.$touch();
      this.$v.password.$touch();
      if (!this.nocaptcha) {
        this.isLoading = false;
        this.$bvToast.toast("reCAPTCHA Required", {
          title: `Error`,
          solid: true,
          variant: "danger",
        });
      } else {
        let data = { email: this.email, password: this.password };
        axios({
          method: "POST",
          url: "/v1/login-aff",
          data: data,
        }).then((res) => {
          if (res.data.success) {
            if (res.data.type == 2) {
              let response = res.data;
              let data = response.data.user;
              let type_user = response.data.type_user;
              let token = response.jwt;
              localStorage.setItem("user", JSON.stringify(data));
              localStorage.setItem("type", type_user);
              localStorage.setItem("key", token);
              this.isLoading = false;
              this.$swal
                .fire({
                  icon: "success",
                  title: "Login Success",
                  showConfirmButton: false,
                  timer: 1000,
                })
                .then(() => {
                  this.$router.push({ name: "home" });
                });
            } else {
              this.isLoading = false;
              let response = res.data;
              let data = response.data.user;
              let type_user = response.data.type_user;
              localStorage.setItem("scan", 1);
              localStorage.setItem("user", JSON.stringify(data));
              localStorage.setItem("type", type_user);
              this.$router.push({ name: "otp" });
            }
          } else {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.message,
            });
          }
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <!-- <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div> -->
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div class="login-box">
                      <div class="text-center">
                        <div>
                          <div class="logo">
                            <img
                              src="@/assets/images/logo-2.png"
                              height="50"
                              alt="logo"
                            />
                          </div>
                        </div>

                        <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                        <p class="text-muted">Sign in</p>
                      </div>

                      <div class="p-2 mt-5">
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToLogIn"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="email"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.email.email"
                                >Please enter valid email.</span
                              >
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Password</label>
                            <input
                              v-model="password"
                              :type="showPassword ? 'text' : 'password'"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{
                                'is-invalid': submitted && $v.password.$error,
                              }"
                            />
                            <a
                              class="auti-custom-input-icon2"
                              @mousedown="showPassword = !showPassword"
                              @mouseup="showPassword = !showPassword"
                              @touchstart="showPassword = !showPassword"
                              @touchend="showPassword = !showPassword"
                            >
                              <i
                                class="fas"
                                :class="{
                                  'fa-eye-slash': showPassword,
                                  'fa-eye': !showPassword,
                                }"
                              ></i>
                            </a>
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                          </div>

                          <!-- <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                            >Remember me</label>
                          </div> -->
                          <vue-recaptcha
                            ref="recaptcha"
                            @verify="onVerify"
                            @expired="onExpired"
                            :sitekey="sitekey"
                          ></vue-recaptcha>
                          <small
                            class="mb-0 text-danger"
                            v-if="submitted && !this.nocaptcha"
                          >
                            reCAPTCHA is required
                          </small>
                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              <span v-if="isLoading"><b-spinner small class="m+2" role="status"></b-spinner></span>
                              <span v-else>Log In</span>                              
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link
                              tag="a"
                              to="/forgot-password"
                              class="text-muted"
                            >
                              <i class="mdi mdi-lock mr-1"></i> Forgot your
                              password?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <!-- <p>
                          Don't have an account ?
                          <router-link
                            tag="a"
                            to="/register"
                            class="font-weight-medium text-primary"
                          >Register</router-link>
                        </p> -->
                        <p>
                          © 2022 Adaremit
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
